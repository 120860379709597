@import '../../styles/propertySets.css';

.root {
  width: 100%;
  max-width: 1440px;
  background-color: #FEB5AB;
  margin: 0 auto;
  display: flex;
  flex: 1;
}

.practitionerAboutContainer {
  padding: 24px 40px;
  color: #f2240d;
  width: 50%;
  height: 540px;
  overflow-y: auto;
  /*-ms-overflow-style: none;*/
  /*scrollbar-width: none;*/
}

@media (max-width: 700px) {
  .root {
    display: block;
  }
  .practitionerAboutContainer {
    width: 100%;
    height: auto;
  }
}

/*.practitionerAboutContainer::-webkit-scrollbar {*/
/*  display: none;*/
/*}*/

.practitionerAboutImgContainer {
  position: relative;
  height: 540px;
  width: 50%;
  margin: 0 auto;
  padding: 20px 0;
  color: #FF6353;
  background: hsla(0, 0%, 0%, .05);
  display: flex;
  justify-content: center;
  align-items: center;
}
.practitionerAboutContainer span { font-size: 20px; }

.bio {
  line-height: 1.8;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 20px;
  white-space: pre-line;
}

.gateCommon {
  position: absolute;
  border: 1px solid yellow;
  box-shadow: 0 0 2px 1px yellow, inset 0 0 2px 1px yellow;
}
.gateTop {
  top: 60px;
  height: 300px;
  width: 300px;
  border-radius: 50%;
  mask-image: linear-gradient(to bottom, #000 50%, transparent 50%);
}
.gateBottom {
  bottom: 50px;
  top: 206px;
  width: 300px;
  mask-image: linear-gradient(to bottom,transparent 4px, black 4px);
}

.gateMiddle {
  position: absolute;
  left: calc(50% - 38px);
  top: calc(50% - 38px);
  width: 100px;
  height: 100px;
  background-image: url(./images/icon.svg);
  background-size: contain;
}
