@import '../../styles/propertySets.css';

.main {
  padding: 21px 24px 21px 24px;
}

.summary {
  margin: 0;
}

.userCards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 20px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.userItem {
  margin-bottom: 36px;
  /*width: 300px;*/
  /*flex-basis: 100%;*/

  /*@media (--viewportLarge) {*/
  /*!***/
  /*  * resultPanelWidthRatio = 0.625 aka 62.5%*/

  /*  resultPanel: 62.5vw from 1024px = 640px*/
  /*  panelPaddings: - 2*36px = 72px*/

  /*  columnCount: 2*/
  /*  guttersBetweenColumns: 24px*/

  /*  (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth*/
  /*  ergo => listingCardWidth: 272px*/

  /*  * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))*/
  /*  *!*/
  /*  flex-basis: calc(25% - 24px);*/
  /*  margin-right: 24px;*/
  /*}*/

  /*@media (--viewportXLarge) {*/
  /*  !***/
  /*   * resultPanelWidthRatio = 0.625 aka 62.5%*/

  /*    resultPanel: 62.5vw from 1920px = 1200px*/
  /*    panelPaddings: - 2*36px = 72px*/

  /*    columnCount: 3*/
  /*    guttersBetweenColumns: 2*24px = 48px*/

  /*    (resultPanel - (panelPaddings + guttersBetweenColumns)) / columnCount = listingCardWidth*/
  /*    ergo => listingCardWidth: 360px*/

  /*   * flex-basis: calc((100%/columnCount) - (guttersBetweenColumns / columnCount))*/
  /*   *!*/

  /*  flex-basis: calc(33.33% - 16px);*/
  /*  margin-right: 24px;*/
  /*}*/
}

.userItemImg {
  width: 300px;
  height: 300px;
  object-fit: cover;

  &:hover {
    transform: scale(1.01);
  }
}

.hostname {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
