@import '../../styles/propertySets.css';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (--viewportMedium) {
    justify-content: flex-start;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.gate {
  height: 600px;
  width: 400px;
  color: white;
  position: relative;
  border: none;
}

.title {
  @apply --marketplaceH1FontStyles;
}

.subtitle {
  @apply --marketplaceH2FontStyles;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: black;
  mix-blend-mode: multiply;
  opacity: 0.5;
}

.gateContent {
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gateItem {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.gateControls {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
}

.controlLine {
  background: white;
  height: 1px;
  margin: 10px;
  width: 30%;
}

.leftArrow {
  width: 16px;
}

.rightArrow {
  width: 16px;
  transform: rotate(180deg);
}

.leftArrow path {
  fill: white;
}

.rightArrow path {
  fill: white;
}

.verticalLine {
  width: 1px;
  margin: 10px;
  background: white;
  height: 50px;
}

.actionButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.8s;

  display: block;
  width: 260px;
}
