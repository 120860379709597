
.tab {
  margin-left: 15px;
  padding: 0 10px;
  white-space: nowrap;

  @media (--viewportMedium) {
    display: flex;
    align-items: flex-start;
    height: 100%;
    padding: initial;

    &:first-child {
      margin-left: 0;
    }
  }
}

.nav {
  padding-top: 13px;
  justify-content: flex-start;

  @media (--viewportMedium) {
    display: flex;
    justify-content: flex-start;
    height: 57px;
    align-items: flex-start;
    padding: 13px 24px 0 24px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}
