@import '../../styles/propertySets.css';

.root {
  /* Layout */
  /*display: flex;
  flex-direction: column;*/

  /* Remove link's hover effect */
  /*&:hover {
    text-decoration: none;
  }*/
}

.calendarFrame {
  width: 100%;
  min-height: 80px;
  margin-top: 24px;
  padding-bottom: 160px;
  box-shadow: 0 0 8px .25px black;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;
  --hourhi: 24px;
}
.calendarFrame > h2 { padding-left: 16px; }
.calendarWeek {
  display: block;
  padding: 4px 0;
  margin: 4px 0 64px;
  /*box-shadow: inset 0 0 3px 1px red;*/
}
.calendarDay {
  position: relative;
  display: inline-block;
  min-width: 100px;
  width: 14%;
  min-height: 480px;
  margin: 0 1px; 
  /*box-shadow: 0 0 3px .5px #00000080;*/
  padding: 0 2px;
  height: calc(var(--hours) * var(--hourhi));
}
.calendarDay.sat {background: #00000010;}
.calendarDay.sun {background: #00000020;}
.dayHead { text-align: center;}
.headDate { text-align: center; }
.headWeekday { text-align: center; }

.dayBody {
  position: relative; width: 100%; height: calc(28 * var(--hourhi));
  margin-top: calc(var(--hiddenHours) * var(--hourhi));
  padding: 0 4px;
}
.rulerFrame { position: absolute; inset: 0; }
.hourRuler {
  position: absolute; top: calc(var(--hour) * var(--hourhi)); width: 100%; height: 0;
  border-top: 1px solid hsla(0, 0%, 0%, .2);
  opacity: .5;
}
.hourRulerTime {
  position: absolute; width: 40px;
  color: #888;
  text-align: right; font-family: helvetica;
}
.dayBody:hover .hourRuler { opacity: 1; }

.slot {
  position: absolute;
  left: 2px; right: 2px;
  background: linear-gradient(to right, hsla(var(--hue), 90%, 60%, .1), hsla(var(--hue), 90%, 40%, .5));
  box-shadow: 0 0 6px .5px #00000060; 
  font: 400 13px roboto condensed;
  padding: 2px 3px; overflow: hidden; text-overflow: ellipsis;
}
.slot:not([slotype=grpbk]):hover { right: unset; min-width: max-content; /* padding-bottom: 50px; */ }
.slot[slotype=mc] {--hue: 120;  border-radius: 8px;}
.slot[slotype=grp] {--hue: 120; --lhue: 200; --b: black; --c: hsl(var(--lhue), 75%, 40%);
  background: linear-gradient(to bottom, var(--c) 15%, var(--b) 50%, var(--c) 85%);
  background: linear-gradient(to bottom, var(--b) 10%, var(--c) 50%, var(--b) 90%);
  box-shadow: 0 0 6px 1px #00000080; 
  left: 25px; right: 4px;
  color: white; border-radius: 3px;
}
.slot[slotype=grpbk] {--hue: 120; --lhue: 200; --b: black; --c: hsl(var(--lhue), 75%, 40%);
  backdrop-filter: hue-rotate(120deg);
  left: 25px; right: 4px;
  color: transparent; background: none;
  border-radius: 3px;
  /*pointer-events: none;*/
}
.slot[slotype=priv] {--hue: 120; --ghue: 90; --c: hsl(var(--ghue), 90%, 25%);
  background: repeating-linear-gradient(45deg, black 1px, var(--c) 2px, var(--c) 3px, black 4px, black 5px);
  left: 25px; right: 4px;
  color:#ffc; border-radius: 6px;
}
.timeLine {
  position: absolute;
  width: 100%; height: 0px;
  box-shadow: 0 0 1.5px 1px orangered;
}