@import '../../styles/propertySets.css';

.root {
  display: flex;
  height: 400px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.backgroundGate {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  height: 70px;
  width: 50px;
  border: 1px solid white;
  opacity: 0.4;

  @media (--viewportMedium) {
    height: 300px;
    width: 200px;
  }
}

.leftContainer {
  width: 50%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.links {
  width: 50%;
  height: 100%;
  display: flex;
  padding-left: 40px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.socialLink {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title {
  @apply --marketplaceH1FontStyles;
  text-align: left;
  color: #FF6353;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  text-align: left;
  font-weight: normal;
}

.logo {
  width: 80px;
  height: 80px;
  margin: 10px;
}

.message {
  /* @apply --marketplaceH1FontStyles; */
  font-size: 20px;
  color: black;
  color: #777;
  margin-left: 1em;
}

.line {
  height: 2px;
  width: 30%;
  background: white;
  margin-left: 20px;
  margin-right: 20px;
}

.rightArrow {
  width: 20px;
  height: 20px;
}

.rightArrow path {
  fill: #FF6353;
  transform: rotate(180deg);
}
