@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.cards {
  width: 100%;
  display: flex;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: #373A40;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  color: #A98E75;
}

.gate {
  height: 200px;
  width: 150px;
  margin-left: 4px;
  display: none;

  border: 2px solid #A98E75;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.gateStar svg {
  margin-top: 10px;
  fill: none;
}

.gateStar svg > path {
  stroke: #A98E75;
}

.gateControls {
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.gateControls > svg > path {
}

.controlLine {
  background: var(--marketplaceSecondaryColor);
  height: 2px;
  margin: 10px;
  flex: 1;
}

.leftArrow {
  width: 17px;
}

.rightArrow {
  width: 17px;
  transform: rotate(180deg);
  align-items: right;
}

.services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.services > * {
  width: 200px;
  margin: 20px;
  height: 300px;
  color: var(--matterColorLight);
}

.listingCardsWrapper {
  width: 100%;
  height: auto;
  overflow-x: scroll;
}

.listingCardsWrapper::-webkit-scrollbar { background: transparent; width:0px; height: 8px; }
.listingCardsWrapper::-webkit-scrollbar-track {background: #00000020; border-radius: 8px; }
.listingCardsWrapper::-webkit-scrollbar-thumb { background: #118569; border-radius: 8px; }

.listingCards {
  width: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: auto;
}

.listingCard {
  margin: 8px;
  width: 250px;
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportLarge) {
    flex-basis: calc(50% - 12px);
    margin-right: 24px;
  }

  @media (--viewportXLarge) {
    flex-basis: calc(33.33% - 16px);
    margin-right: 24px;
  }
}
