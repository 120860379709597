@import '../../styles/propertySets.css';

.item {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-right: 32px;
}

.item::before {
  content: url("../../assets/ellipse.svg");
  vertical-align: middle;
  padding: 0 8px 0 0;
}

.item::before svg {
  width: 22px;
  height: 22px;
}
