@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  @media (--viewportMedium) {
   flex-direction: row;
  }
}

.gate {
  height: 70vh;
  width: 80%;
  max-width: 400px;
  color: white;
  position: relative;
  border-color: #F7DF4F;
}

.gateContent {
  color: #F7DF4F;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gateControls {
  margin-top: 60px;
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-around;
}

.controlLine {
  background: #F7DF4F;
  height: 2px;
  margin: 10px;
  width: 30%;
}

.leftArrow {
  width: 25px;
}

.rightArrow {
  width: 25px;
  transform: rotate(180deg);
}

.leftArrow path {
  fill: #F7DF4F;
}

.rightArrow path {
  fill: #F7DF4F;
}

.heroContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 64px 0 64px;
  color: var(--matterColorDark);
  flex-basis: 50%;
  min-height: 70vh;
}

.buttonRow {
  margin-top: 10%;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  justify-content: space-between;
}

.services {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px;
  background-image: url(../../assets/bg-1.png);
  background-position: center center;
  background-size: cover;
}

.servicesBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}

.serviceTitle {
  @apply --marketplaceH1FontStyles;
  font-size: calc(20px + 1.6vw);
  width: 100%;
  position: absolute;
  top: calc(50% - 80px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorDark);

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
  }
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--matterColorDark);
  margin: 0 0 32px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 47px 0;
  }
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  margin-right: 10px;
  flex: 1;

  animation-delay: 0.8s;
}

.joinButton {
  @apply --marketplaceButtonStyles;
  flex: 1;
  composes: animation;

  background: transparent;
  color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);

  animation-delay: 0.8s;
}

.joinButton:hover, .joinButton:active {
  color: white;
}
