@import '../../styles/propertySets.css';

.root {
  border-radius: 999px 999px 0 0;
  border: 1px solid var(--matterColor);
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.root:hover {
  color: blue;
}

.gateInner {
  height: 101%;
  margin-top: -1%;
  width: 100%;
  padding: 2px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 1;

  &:hover a {
    color: var(--marketplaceColor);
  }
}
