@import '../../styles/propertySets.css';

.root {
  width: 100%;
  max-width: 1440px;
  background: var(--matterColorBright);
  margin: 0 auto;
  display: flex;
  flex: 1;
  border-top: 6px solid hsl(28, 26%, 76%);
}
@media (max-width: 700px) {
  .root {
    display: block;
  }
}
@media (max-width: 620px) {
  .root .astrologyInline {
    display: block;
    width: auto;
    margin-bottom: 12px;
  }
  .root .astrologyLabel {
    margin-top: 10px; 
  }
}
.profilePictureContainer {
  width: 300px;
  height: 450px;
  background-size: cover;
}

.dataContainer {
  flex: 6;
  padding-top: 40px;
  padding-left: 36px;
}
.dataContainer h1 { margin-top: 0px; margin-bottom: 12px; }  /* CHK */

.practitionerNameLabel {
  font-size: 20px;
  line-height: 37px;
  color: var(--matterColorNegative);
}

.practitionerLanguagesLabel, .practitionerQualificationsLabel, .astrologyLabel {  /* CHK */
  margin-top: 20px;  /* CHK */
  font-size: 20px;
  line-height: 37px;
  color: var(--matterColorNegative);
}

.practitionerLanguagesLabel + ul { margin-top: 0; }  /* CHK */

.extraInfoLocationCity {
  color: var(--marketplaceColorSecondary)
}

.extraInfoVerified {
  color: #53CE86;
}

.extraInfoVerified:before {
  content: url("../../assets/checkmark.svg");
  vertical-align: middle;
  padding: 4px;
  padding-left: 2px;  /* CHK */
}

.languagesLanguage {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  margin-right: 16px;
}

.languagesLanguage::before {
  content: url("../../assets/ellipse.svg");
  vertical-align: middle;
  padding: 0 8px 0 0;
}

.astrologyInline {  /* CHK */
  display: inline-block;
  width: 25%;
  margin-bottom: 20px;
}

.astrologySign {  /* CHK */
  content: 'dummy';
}

.astrologySign::before {
  background: var(--svg);
  display: inline-block;
  content: '';
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  background-size: 100%;
}

.astrologySign span {
  position: relative;
  top: -12px;
  left: 12px;
  font-weight: 600;
}
.astrologySign span[type=moon] { color: var(--black); }
.astrologySign span[type=star] { color: hsl(145, 56%, 57%); }
.astrologySign span[type=element] { color: var(--marketplaceColorSecondary); }
