@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  @apply --marketplaceH1FontStyles;
  text-align: center;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.videosPlaceholder {

}

.videos {

}
