@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150vh;

  @media (--viewportMedium) {
    flex-direction: row;
    height: 800px;
  }
}

.leftContainer {
  flex: 1;
  background: #ADC9FF;
  color: var(--marketplaceColorSecondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.practitionerContainer {
  flex: 1;
  height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/shadows-2.png);
  background-size: cover;
}

.search {
  height: 20px;
}

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorDark);
  text-align: center;
  padding: 0 10px;
}

.verticalLine {
  width: 1px;
  margin: 10px;
  background: var(--marketplaceColorSecondary);
  height: 50px;
}

.viewAll {
  display: flex;
}

.viewAll > svg {
  margin-left: 10px;
  width: 20px;
  transform: rotate(180deg);
}

.gate {
  height: 100%;
  width: 80vw;
  border-color: transparent;
  border-width: 0;
  position: relative;

  @media (--viewportMedium) {
    width: 400px;
  }
}

.gateContainer {
  margin: 20px;
  height: 100%;
  padding: 30px 10px 20px 10px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.practitionerProfile {
  display: flex;
  flex-direction: column;
  background: #A98E75;
  width: 100%;
  height: 100%;
  text-align: left;
  color: white;
}

.practitionerProfile > * {
  padding: 0 10px 0 10px;
  border-bottom: 2px solid #D2C1B2;
}

.practitionerProfile .photo {
  flex: 1;
  padding: 0;
  object-fit: cover;
  border-bottom: none;
}

.practitionerProfile .name {
  @apply --marketplaceH1FontStyles;
  font-size: 25px;
  margin: 0;
  height: 45px;
}

.practitionerProfile .location {
  height: 36px;
  line-height: 2;
}

.practitionerProfile .language {
  height: 36px;
  line-height: 2;
}

.practitionerProfile .skills {
  height: 36px;
  display: flex;
  align-items: baseline;
  line-height: 2;
  margin-bottom: 36px;
}

.skills .skill::before {
  content: "";
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #F7DF4F;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  position: absolute;
}

.skills .skill {
  padding-left: 7px;
  margin-right: 18px;
  position: relative;
}

.language .languageList {
  color: #FEB5AB;
}

.location .city {
  color: #FEB5AB;
}

.gateControls {
  margin-bottom: 10px;
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-around;
}

.controlLine {
  background: var(--matterColorDark);
  height: 1px;
  margin: 10px;
  flex: 1;
}

.leftArrow {
  width: 17px;
}

.rightArrow {
  width: 17px;
  transform: rotate(180deg);
}

.leftArrow path {
  fill: var(--matterColorDark);
}

.rightArrow path {
  fill: var(--matterColorDark);
}
