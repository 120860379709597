.carousel-root {
  width: 100%;
  height: 100%;
}

.carousel-root .carousel {
  height: 100%;
  width: 100%;
}

.carousel-root .carousel.carousel-slider {
  height: 100%;
}

.carousel-root .carousel .slider-wrapper {
  height: 100%;
}

.carousel-root .carousel .slider-wrapper .slider {
  height: 100%;
}

.carousel-root .carousel .slider-wrapper .slider .slide {
  height: 100%;
}

.control-dots .dot.selected {
  background: #F7DF4F;
}
