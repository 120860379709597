@import '../../styles/propertySets.css';

.root {
  width: 100%;
  max-width: 1440px;
  background: #D2C1B2;
  margin: 0 auto;
  display: flex;
  flex: 1;
  border-top: 16px solid hsl(28, 26%, 76%);  /* CHK */
}

.root::before {
  /* content: url("../../assets/ellipse.svg"); */
  vertical-align: middle;
  padding: 0 8px 0 0;
}

.root.empty::before {
  content: none;
}

.practitionerSpecialitiesContainer {
  padding-top: 0px;
  padding-left: 40px;
}
.practitionerSpecialitiesContainer ul { margin-bottom: 32px; }

.practitionerSpecialitiesLabel {
  margin-top: 8px;  /* CHK */
  font-size: 20px;
  line-height: 37px;
  color: hsl(29, 23%, 56%);
}
