@import '../../styles/propertySets.css';

.root {
  width: 100%;
  max-width: 1440px;
  background-color: #Ffffff;
  margin: 0 auto;
  display: flex;
  flex: 1;
  border-top: 32px solid hsl(28, 26%, 76%);  /* CHK */
}

/* .root::before {
  position: absolute;
  right: 0;
  top: 0;
  color: green;
  content: 'PrivateSessionsSection';
  font-size: 24px;
} */


.sessionImgContainer {
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 20px;
  padding: 0;
  margin: 0;
  color: #FF6353;
  color: #f2240d;
  width: 50%;
  display: inline-block;
  position: relative;
  min-height: 360px;
  background-size: cover;
  width: 35%;
  min-width: 420px;
  height: 520px;
}
.centerO2O {
  top: 100px;
  left: 50%;
  width: fit-content;
  transform: translateX(-50%);
  position: absolute;
  color: yellow;
}
.gateCommon {
  position: absolute;
  left: 15%;
  right: 15%;
  border: 1px solid yellow;
  box-shadow: 0 0 2px 1px yellow, inset 0 0 2px 1px yellow;
}
.gateTop {
  top: 60px;
  height: 300px;
  border-radius: 50%;
  mask-image: linear-gradient(to bottom, #000 50%, transparent 50%);
}
.gateBottom {
  bottom: 50px;
  top: 206px;
  mask-image: linear-gradient(to bottom,transparent 4px, black 4px);
}

.sectionContainer {
  padding: 24px;
}

.dataLabel {
  font-size: 20px;
  color: #bbb;
  padding-bottom: 20px;
}
/* .groupSessionDataLabel {
  font-size: 20px;
  color: #aaa;
  padding: 0 0 0 12px;
}
.groupSessionDataBrowser {
  width: 100%;
  padding-left: 36px;
} */
.title {
  font-size: 19px;
  font-weight: 600;
}
.desc { color: #aaa;}

.price {color: var(--marketplaceColorSecondary);}
