@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.title {
  @apply --marketplaceH1FontStyles;
  text-align: center;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.services {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.services > * {
  width: 200px;
  height: 300px;
  margin: 20px auto;
}

.services h1 {
  font-size: 30px;
  margin: auto auto;
}

.services h1 a {
  text-decoration: none;
  color: white;
}
