@import '../../styles/propertySets.css';

.root {
  display: flex;
  height: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  position: relative;
}

.title {
  @apply --marketplaceH1FontStyles;
  text-align: center;
}

.subtitle {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

/*.gate {*/
/*  height: 400px;*/
/*  width: 300px;*/
/*  border-color: #F7DF4F;*/
/*  border-width: 2px;*/
/*  position: absolute;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*}*/

.verticalLine {
  width: 1px;
  margin: 10px;
  background: #F7DF4F;
  height: 50px;
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}


.joinButton {
  @apply --marketplaceButtonStyles;
  flex: 1;
  composes: animation;

  background: transparent;
  color: var(--marketplaceColor);
  border: 2px solid var(--marketplaceColor);

  animation-delay: 0.8s;
}
