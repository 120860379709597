@import '../../styles/propertySets.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 100%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}
.edit {
  visibility: hidden;
  position: absolute;
  background: #800;
  color: white;
  left: 0;
  top: 0;
  padding: 4px 8px;
  font-size: 18px;
}
.aspectWrapper:hover .edit {visibility: visible;}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--borderRadius);
}

.pill {
  position: absolute;
  color: white;
  padding: 4px 8px;
  font-size: 14px;
  right: 0;
}

.location {
  position: absolute;
  background-color: white;
  right: 0;
  top: 40px;
  padding: 4px 8px;
  font-size: 14px;
  color: #A98E75;
}

.info {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 8px 0 2px 0;
}

.price {
  /* Layout */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  flex-shrink: 0;
}

/*.location {*/
/*  !* Layout *!*/
/*  width: 100%;*/
/*  margin-right: 18px;*/
/*  font-weight: 500;*/
/*  font-size: 15px;*/
/*  color: #A98E75;*/
/*  margin-top: 6px;*/
/*}*/

.location > span {
  color: var(--marketplaceColorSecondary);
}

.host {
  /* Layout */
  width: 250px;
  font-weight: 500;
  font-size: 15px;
  color: #484848;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.host > svg {
  margin-top: -3px;
}

.hostname {
  display: inline;
  color: var(--marketplaceColorSecondary);
}

.priceValue {
  /* Font */
  font-size: 22px;
  color: #484848;
  font-weight: normal;
  font-size: 14px;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.priceValue > span {
  font-size: 18px;
}


.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.title {
  /* Font */
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.owner {
  display: block;
  color: var(--matterColor);
}

.certificateInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.languages {
  display: block;
  color: var(--matterColor);
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.avatar.avatar {
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50% 50% 0 50%;
  border: 5px solid var(--sCol);
}

.info.info a:hover {text-decoration: none;}